import { createRouter, createWebHashHistory } from 'vue-router';
import { Notify } from 'vant';
import HomeView from '../views/HomeView.vue';
import axios from 'axios';
import { v1 as uuidv1 } from "uuid";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
NProgress.configure({ showSpinner: false });
const routes = [
    {
        path: '/',
        name: 'home',
        meta: {
            title: "创作者中心",
        },
        component: HomeView
    },
    {
        path: '/login',
        name: '帐号登陆',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/register',
        name: '帐号注册',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/my',
        name: '用户中心',
        component: () => import('../views/My.vue')
    },
    {
        path: '/picture',
        name: '我的素材',
        component: () => import('../views/Picture.vue')
    }
];
const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(process.env.BASE_URL),
    routes
});
if (localStorage.getItem("uid")) {
    // 如果存在uid则15分钟刷新一次token
    setInterval(function () {
        let authorization = localStorage.getItem("authorization");
        let timestamp = JSON.parse(authorization).timestamp;
        let _timestamp = new Date().valueOf();
        if ((timestamp - _timestamp) < 1000 * 60 * 30) {
            axios.post(process.env.VUE_APP_URL + "/token", {
                loginType: "wallpaper",
                uuid: localStorage.getItem("uid")
            }).then((res) => {
                if (res.data.code === 0) {
                    let _timestamp = new Date().valueOf();
                    let authorization = {
                        token: res.data.token,
                        timestamp: _timestamp + 1000 * 60 * 60,
                    };
                    localStorage.setItem("authorization", JSON.stringify(authorization));
                }
            });
        }
    }, 1000 * 60 * 25);
}
axios.interceptors.request.use(response => {
    let authorization = localStorage.getItem("authorization") || null;
    if (authorization) {
        // 判断是否存在token，如果存在的话，则每个http header都加上token
        response.headers.Authorization = `Bearer ${JSON.parse(authorization).token}`;
    }
    return response;
}, err => {
    return Promise.reject(err);
});
axios.interceptors.response.use(response => {
    return response;
}, err => {
    if (err.response) {
        switch (err.response.status) {
            case 401: {
                localStorage.removeItem("authorization");
                Notify({ type: 'danger', message: '鉴权失败，请重新登陆' });
                router.replace("/");
                // router.go(-1)
                // location.reload()
                break;
            }
            default: {
                if (err.response.data.error !== "jwt expired") {
                    Notify({ type: 'danger', message: err.response.data.errmsg || err.response.data.error || '系统错误' });
                }
            }
        }
    }
    else {
        Notify({ type: 'danger', message: err });
    }
    return Promise.reject(err);
});
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    NProgress.start();
    let authorization = localStorage.getItem("authorization") || null;
    let _timestamp = (new Date()).valueOf();
    if (authorization) {
        authorization = JSON.parse(authorization);
        if (_timestamp > authorization.timestamp) {
            authorization = null;
        }
    }
    if (authorization === null) {
        let uuid = uuidv1().replace(/-/g, "");
        localStorage.setItem("uid", uuid);
        axios.post(process.env.VUE_APP_URL + "/token", {
            loginType: "wallpaper",
            uuid: uuid,
        }).then((res) => {
            if (res.data.code === 0) {
                let _timestamp = new Date().valueOf();
                let authorization = {
                    token: res.data.token,
                    timestamp: _timestamp + 1000 * 60 * 60,
                };
                localStorage.setItem("authorization", JSON.stringify(authorization));
            }
        });
    }
    next();
});
router.afterEach(() => {
    NProgress.done();
});
export default router;
