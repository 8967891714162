import { createStore } from 'vuex';
export default createStore({
    state: {
        uid: null
    },
    getters: {},
    mutations: {
        setUid: (state, uid) => {
            state.uid = uid;
            sessionStorage.setItem("uid", uid);
        },
        delSessionStorage: (state, key) => {
            if (key) {
                sessionStorage.removeItem(key);
            }
        },
        delLocalStorage: (state, key) => {
            if (key) {
                localStorage.removeItem(key);
            }
        }
    },
    actions: {},
    modules: {}
});
