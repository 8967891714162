import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vant from 'vant';
import { Lazyload } from 'vant';
import TDesign from 'tdesign-mobile-vue';
createApp(App).use(store).use(router).use(TDesign).use(vant).use(Lazyload).mount('#app');
if (sessionStorage.getItem('uid')) {
    store.commit('setUid', sessionStorage.getItem('uid'));
}
else if (!sessionStorage.getItem('uid') && localStorage.getItem('userInfo')) {
    store.commit('setUid', JSON.parse(localStorage.getItem('userInfo')).uid);
}
