<template>
  <div class="flex-box">
    <div class="variable">
      <router-view />
    </div>
    <div class="bottom">
      <van-divider>Copyright &copy; 2022 All Rights Reserved</van-divider>
    </div>
  </div>
</template>

<style lang="stylus">
html {
  background: #1a1a1a;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-width: 350px;
  max-width: 750px;
  min-height: 100vh;
  margin: 0 auto;
  background: #fff;
}

.flex-box {
  display: flex;
  min-height: 100vh;
  flex-flow: row wrap;
}

.variable {
  width: 100%;
  align-self: flex-start;
}

.bottom {
  width: 100%;
  align-self: flex-end;
}
</style>
